<template>
  <div id="profile">
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            My Account
            <RChangeRequestButton :before-open="setContactSupportFlag" />
          </CCardHeader>
          <CCardBody>
            <RWidgetInfo icon="md-user" label="Name" :value="account.name" />
            <RWidgetInfo icon="md-email" label="Email" :value="account.email" />
            <RWidgetInfo icon="md-phone" label="Phone" :value="account.phone" />
            <RWidgetInfo icon="md-event-note" label="Title" :value="account.title" />
            <RWidgetInfo icon="md-people" label="Companies" :value="accountCompanies" />
            <RWidgetInfo icon="md-apartment" label="Properties" :value="accountProperties" />
            <RWidgetInfo label="Created On" :value="format(account.created)" />
            <RWidgetInfo label="Last Login" :value="format(account.last_login)" />
            <RWidgetInfo label="Active" :value="capitalize(account.is_active)" />
            <div v-if="$store.state.root.devMode">
              <hr>
              <RWidgetInfo label="Staff" :value="capitalize(account.is_staff)" />
              <RWidgetInfo label="Admin" :value="capitalize(account.is_superuser)" />
              <RWidgetInfo label="Group Permissions" :value="groupPermissions" />
              <RWidgetInfo label="User Permissions" :value="userPermissions" />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { RChangeRequestButton } from '@/components/buttons'
import { RWidgetInfo } from '@/components/widgets'
import { RooofAPI } from '@/services/api/resources'
import { capitalize, formatDate, intersection } from '@/utils'

export default {
  name: 'Profile',
  components: {
    RChangeRequestButton,
    RWidgetInfo
  },
  data () {
    return {
      properties: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.auth.account
    }),
    accountCompanies () {
      if (this.account.is_staff) {
        return 'All companies'
      }
      // We don't want to use account.postengine_groups here because we still
      // want to show if a user is indirectly associated with a company via
      // direct property associations.
      return this.$store.state.rooof.companies.map(company => company.human_name).join(', ')
    },
    accountProperties () {
      if (this.account.is_staff) {
        return 'All properties'
      }
      // Prevent displaying 'Company (all properties), PropertyOfCompany'
      const properties = this.properties.filter(prop => {
        return intersection(prop.postengine_groups, this.account.postengine_groups.map(membership => membership.group.id)).length === 0
      }).map(prop => prop.name)

      for (const membership of this.account.postengine_groups) {
        const groupName = membership.group.name || `Top Level (${membership.group.company.human_name})`
        properties.unshift(`${groupName} [all properties]`)
      }
      return properties.join(', ')
    },
    groupPermissions () {
      return this.account.is_superuser ? 'All'
        : this.account.postengine_groups.map(membership => membership.group.name ||
        `Top Level (${membership.group.company.human_name})`).join(', ')
    },
    userPermissions () {
      return this.account.is_superuser ? 'All' : this.account.user_permissions.join(', ')
    }
  },
  created () {
    this.capitalize = capitalize
    this.fetchProperties()
  },
  methods: {
    /**
     * Each account can be associated directly with
     * groups and/or properties. For properties, the api
     * returns a list of ids. For groups, the `name`
     * field is used instead.
     *
     * This uses those property ids to fetch the actual
     * property objects from the api in order to display
     * information about them (such as their name).
     */
    async fetchProperties () {
      const promises = this.account.rooof_properties.map(pid => {
        return RooofAPI.properties.retrieve({ id: pid })
      })
      const responses = await Promise.all(promises)
      this.properties = responses.map(response => response.data)
    },
    /**
     * Fetch a stored company using the `name` attr.
     *
     * @param {String} name
     * @return {Object}
     */
    getCompanyByName (name) {
      return this.$store.getters['rooof/getCompanyByName'](name)
    },
    /**
     * Format an ISO-8601 date string into a more
     * user-friendly format.
     *
     * @param {String} date
     * @returns {String}
     */
    format (date) {
      return formatDate(date, 'Pp')
    },
    /**
     * Sets the "My profile information is incorrect" checkbox
     * in the Contact Support modal.
     */
    setContactSupportFlag () {
      this.$store.commit('support/set', ['incorrect_profile', true])
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  font-weight: 800;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
}
</style>
